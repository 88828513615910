import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from '../../core/services/toast.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login-manager',
  templateUrl: './login-manager.component.html',
  styleUrl: './login-manager.component.scss'
})
export class LoginManagerComponent {
  loginForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private toast: ToastService,
    private router: Router
  ) {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }

    this.auth.loginManagement(this.loginForm.value).subscribe({
      next: (response: any) => {
        console.log('Login response:', response);
        this.router.navigate(['/admin/dashboard']);
        this.toast.show({ textOrHtml: 'Successfully logged in!', classname: 'bg-primary text-light', delay: 1500 });
      },
      error: (error: any) => {
        console.log('Login error:', error);
        this.router.navigate(['/login-admin']);
        this.toast.show({ textOrHtml: 'Invalid email or password', classname: 'bg-danger text-white', delay: 1000 });
      }
    });
  }

}
