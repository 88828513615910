<div class="container bg-white py-5">
  <div class="row">
    <div class="col-12 d-block d-md-none">
      <img cCardImg="top" src="../../../assets/images/f1.png" width="112" class="mx-auto mt-3 mb-2 d-block d-md-none" role="button" alt="Franchise One Logo" [routerLink]="['/']">
    </div>
  </div>
  <ol class="numbered-list list-inline">
    <li class="list-inline-item done" data-step="1"><span>Choose a Role</span></li>
    <li class="list-inline-item done" data-step="2"><span>Create Account</span></li>
    <li class="list-inline-item done" data-step="3"><span>Account Verification</span></li>
    <li class="list-inline-item active" data-step="4"><span>Update Information</span></li>
  </ol>
  <div class="row pt-5" style="height: 80vh;">
    <div class="col-12 form-container d-flex flex-column justify-content-between">
      <h2 class="text-center fw-bold text-primary">Basic Information</h2>
      <h6 class="card-title text-center mb-5 fw-normal text-secondary">We require your basic business information to gain a deeper understanding of your enterprise</h6>
      <div style="width: 400px" class="card flex-fill mx-auto border-0">
        <div class="card-body mt-3">
          <form [formGroup]="basicForm" cForm (submit)="onSubmit($event)" class="h-100 d-flex flex-column justify-content-between">
            <div class="row flex-grow-1">
              <div class="col-12" *ngIf="accountDetail?.activeRole === 'Franchisee'">
                <div class="mb-3">
                  <label for="budgetTier">Budget</label>
                  <select id="budgetTier" class="form-select" formControlName="budgetTier">
                    <option value="" selected disabled>- select budget -</option>
                    <option value="1">Under 10 million</option>
                    <option value="2">10 million - 100 million</option>
                    <option value="3">100 million - 500 million</option>
                    <option value="4">500 million - 1 billion</option>
                    <option value="5">1 billion - 5 billion</option>
                    <option value="6">Over 5 billion</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="occupation">Occupation</label>
                  <select id="occupation" class="form-select" formControlName="occupation">
                    <option value="" selected disabled>- select occupation -</option>
                    <option value="Entrepreneur">Entrepreneur</option>
                    <option value="Home Maker">Home Maker</option>
                    <option value="Professional">Professional</option>
                    <option value="Retired">Retired</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="hadFranchise">Ever had a franchise?</label>
                  <select id="hadFranchise" class="form-select" formControlName="hadFranchise">
                    <option [value]="true">Yes</option> 
                    <option [value]="false">No</option> 
                  </select>
                </div>
                <div class="mb-3">
                  <label for="haveTime">Have time to manage operations?</label>
                  <select id="haveTime" class="form-select" formControlName="haveTime">
                    <option [value]="true">Yes</option> 
                    <option [value]="false">No</option> 
                  </select>
                </div>
              </div>
              <div class="col-12" *ngIf="accountDetail?.activeRole === 'Franchisor'">
                <div class="mb-3">
                  <label for="activeOutlet">Number of Active Outlets</label>
                  <input class="form-control" id="activeOutlet" formControlName="activeOutlet" type="number" placeholder="Ex: 1" />
                </div>
                <div class="mb-3">
                  <label for="ownedStores">How Many of Those Are Owned Stores?</label>
                  <input class="form-control" id="ownedStores" formControlName="ownedStores" type="number" placeholder="Ex: 1" />
                </div>
                <div class="mb-3">
                  <label for="targetOutlet">Numbers of Target Outlets Within Next 12 Months</label>
                  <input class="form-control" id="targetOutlet" formControlName="targetOutlet" type="number" placeholder="Enter your number of target outlet" />
                </div>
                <div class="mb-3">
                  <label for="companyAddress">Company Address</label>
                  <textarea class="form-control" id="companyAddress" rows="3" formControlName="companyAddress" placeholder="Enter company address"></textarea>
                </div>
              </div>
            </div>
            <div class="d-grid mt-3">
              <input role="button" type="submit" class="btn btn-primary col-md-6 mx-auto" value="Register My Account" />
            </div>
            <div class="d-grid text-center mt-3">
              <p>
                <a
                  (click)="skipStep()" role="button"
                  class="text-primary text-decoration-none fw-light"
                  ><small>Skip this step</small></a
                >
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>