<div class="container bg-white py-5">
  <div class="row">
    <div class="col-12 d-block d-md-none">
      <img cCardImg="top" src="../../../assets/images/f1.png" width="112" class="mx-auto mt-3 mb-2 d-block d-md-none" role="button" alt="Franchise One Logo" [routerLink]="['/']">
    </div>
  </div>
  <ol class="numbered-list list-inline">
    <li class="list-inline-item done" data-step="1"><span>Choose a Role</span></li>
    <li class="list-inline-item done" data-step="2"><span>Create Account</span></li>
    <li class="list-inline-item active" data-step="3"><span>Account Verification</span></li>
    <li class="list-inline-item" data-step="4"><span>Update Information</span></li>
  </ol>
  <div class="row pt-5" style="height: 80vh;">
    <div class="col-12 form-container d-flex flex-column justify-content-between">
      <h2 class="text-center fw-bold text-primary">Account Verification</h2>
      <h6 class="card-title text-center fw-normal text-secondary">Please verify your account by entering the OTP code sent to your phone number</h6>
      <div style="width: 400px" class="card flex-fill mx-auto border-0">
        <div class="card-body mt-3">
          <div class="h-100 d-flex flex-column justify-content-between">
            <div class="mb-3 flex-grow-1">
              <div class="otp-inputs">
                <input type="text" maxlength="1" (input)="onOtpInput($event, 0)" (keydown)="onOtpKeyDown($event, 0)" #otp0 />
                <input type="text" maxlength="1" (input)="onOtpInput($event, 1)" (keydown)="onOtpKeyDown($event, 1)" #otp1 />
                <input type="text" maxlength="1" (input)="onOtpInput($event, 2)" (keydown)="onOtpKeyDown($event, 2)" #otp2 />
                <input type="text" maxlength="1" (input)="onOtpInput($event, 3)" (keydown)="onOtpKeyDown($event, 3)" #otp3 />
                <input type="text" maxlength="1" (input)="onOtpInput($event, 4)" (keydown)="onOtpKeyDown($event, 4)" #otp4 />
                <input type="text" maxlength="1" (input)="onOtpInput($event, 5)" (keydown)="onOtpKeyDown($event, 5)" #otp5 />
              </div>
              <div class="d-grid mt-5">
                <button role="button" (click)="sendCode(accountDetail.phoneNumber)" class="btn btn-link d-block d-md-none text-center text-decoration-none">Resend Code</button>
              </div>
            </div>
            <div class="d-grid mt-3">
              <button role="button" class="btn btn-primary" type="button" (click)="submitForm()" [disabled]="verifyOtpForm.invalid">Verify</button>
            </div>
            <div class="d-grid text-center mt-3 d-none d-md-block">
              <p>
                Not receiving your code?
                <a
                  role="button"
                  (click)="sendCode(accountDetail.phoneNumber)"
                  class="text-primary text-decoration-none"
                  ><strong>Resend</strong></a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>