<div class="container-fluid">
  <div class="row">
    <!-- Mobile Logo -->
    <div class="col-12 d-block d-lg-none">
      <img src="../../../assets/images/f1.png" width="212" class="mx-auto mt-3 mb-2 d-block d-md-none" role="button" alt="Franchise One Logo" [routerLink]="['/']">
    </div>

    <!-- Form Container -->
    <div class="col-12 col-lg-4 mx-lg-auto my-5 form-container">
      <div class="card border-0">
        <img src="../../../assets/images/f1.png" width="212" class="mx-auto mt-3 mb-5 d-none d-md-block" role="button" alt="Franchise One Logo" routerLink="/">
        <div class="card-body">
          <form [formGroup]="loginForm" (submit)="onSubmit()">
            <div class="mb-3">
              <label for="email"><strong>Email</strong></label>
              <input id="email" type="email" formControlName="email" #email name="email" class="form-control" required>
              <div *ngIf="loginForm.controls['email'].errors?.['required'] && loginForm.controls['email'].touched">
                <small class="text-danger">Your email is required</small>
              </div>
            </div>
            <div class="mb-3">
              <label for="password"><strong>Password</strong></label>
              <input id="password" type="password" formControlName="password" name="password" class="form-control" required>
              <div *ngIf="loginForm.controls['password'].errors?.['required'] && loginForm.controls['password'].touched">
                <small class="text-danger">Your password is required</small>
              </div>
            </div>
            <div class="text-end">
              <a routerLink="/forgot-password" class="text-primary text-decoration-none"><strong>Forgot password?</strong></a>
            </div>
            <br>
            <div class="d-grid">
              <button type="submit" class="btn btn-primary" [disabled]="!loginForm.valid">Login</button>
            </div>
            <br>
            <div class="text-center">
              <p>Don't have an account? <a routerLink="/register" class="text-primary text-decoration-none"><strong>Register</strong></a></p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
