import { Component, inject } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-toasts',
  template: `
		<ngb-toast
      *ngFor="let toast of toastService.toasts"
      [class]="toast.classname"
      [autohide]="true"
      [delay]="toast.delay || 2000"
      (hidden)="toastService.remove(toast)"
    >
      <ng-container *ngIf="toast.textOrHtml;">
        <div [innerHTML]="toast.textOrHtml"></div>
      </ng-container>
    </ngb-toast>
	`,
	host: { class: 'toast-container position-fixed top-0 end-0 p-3', style: 'z-index: 1200' },
  styleUrl: './toasts.component.scss',
  standalone: true,
  imports: [CommonModule, NgbToastModule],
})
export class ToastsComponent {
  toastService = inject(ToastService);
}