import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ToastService } from '../../core/services/toast.service';
import intlTelInput from 'intl-tel-input';
import { CartService } from '../../core/services/cart.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  @ViewChild('phoneNumber', { static: true }) phoneNumberInput!: ElementRef;
  phoneNumberInstance: any;
  loginForm!: FormGroup;
  private iti: any;
  returnUrl!: string;
  isAuthenticated: boolean | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private toast: ToastService,
    private cart: CartService,
  ) { }

  ngOnInit() {
    this.returnUrl = decodeURIComponent(this.route.snapshot.queryParamMap.get('returnUrl') || '/home');
    const input = this.phoneNumberInput.nativeElement;
    this.iti = intlTelInput(input, {
      initialCountry: 'id',
      separateDialCode: true,
      formatAsYouType: true,
      showFlags: true
    });

    this.loginForm = new FormGroup({
      phoneNumber: new FormControl('', [Validators.required, Validators.minLength(8)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    });
  }

  onSubmit() {
    const countryCode = this.iti.getSelectedCountryData().dialCode;
    const phoneNumber = this.loginForm.get('phoneNumber')?.value.replace(/^[0+]+/, '');

    if (this.loginForm.valid) {
      const fullPhoneNumber = countryCode + phoneNumber;
      this.auth.login(fullPhoneNumber, this.loginForm.value.password).subscribe({
        next: (response: any) => {
          if (response.data.activeRole === 'Franchisee') {
            console.log(response.data);
            localStorage.setItem('userId', response.data.id);
            this.cart.initializeCartData();
            this.router.navigateByUrl(this.returnUrl);
          } else if (response.data.activeRole === 'Franchisor') {
            this.router.navigate(['/dashboard']);
          } else if (response.data.activeRole === 'Management') {
            this.router.navigate(['/admin/dashboard']);
          } else if (response.data.activeRole === 'Sales') {
            this.router.navigate(['/sales']);
          }
          this.toast.show({textOrHtml: 'Successfully logged in!', classname: 'bg-primary text-light', delay: 1500});
        },
        error: (error: any) => {
          console.error('Login error:', error.error.message);
          this.toast.show({textOrHtml: error.error.message, classname: 'bg-danger text-light', delay: 1500});
        }
      });
    }
  }

}
