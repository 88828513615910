<div class="container">
  <div class="row align-items-center mt-5 py-2">
    <div class="col-2">
      <button type="button" class="btn btn-link text-decoration-none" onclick="history.back()">
        <span class="fw-bold"><</span>
      </button>
    </div>
    <div class="col-8 text-center">
      <h5 class="m-0 fw-bold">Password Reset Successful</h5>
    </div>
    <div class="col-2"></div>
  </div>
  <div class="row pt-5" style="height: 75vh;">
    <div class="col-12 col-lg-8 mx-auto form-container d-flex justify-content-between flex-column">
      <h2 class="text-center fw-bold text-primary">Password Reset Successful</h2>
      <img src="../../../../assets/images/reset-password-success.svg" alt="Password Reset Successful" class="img-fluid my-3 mx-auto" style="max-width: 300px;"> 
      <h6 class="card-title text-center text-secondary">Your password has been reset successfully. You can now login with your new password.</h6>
      <div class="card bg-transparent mx-auto border-0 flex-grow-1">
        <div class="card-body mt-5">
          <p> 
            Back to
            <a [routerLink]="['/login']" class="text-primary text-decoration-none"><strong>Login</strong></a>
          </p>
      </div>
    </div>
  </div>
</div>
